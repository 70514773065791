import { Component, computed, inject, OnInit, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/angular/standalone';
import { ArcBadge, ArcButton, ArcIcon, IconNames } from '@appines/archimed';
import { BadgeService } from 'src/services/badge/badge.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
  imports: [CommonModule, FormsModule, IonTabs, IonTabBar, IonTabButton, IonLabel, ArcIcon, ArcButton, ArcBadge]
})
export class TabsPage implements OnInit {
  private badgeService = inject(BadgeService);

  private badges = this.badgeService.state.asReadonly();
  tabItems = computed<{ label: string; iconName: IconNames; rootUrl: string; badges?: Signal<number> }[]>(() => [
    { label: 'Home', iconName: 'House', rootUrl: 'home' },
    { label: 'Emplois', iconName: 'Briefcase', rootUrl: 'job' },
    { label: 'Formations', iconName: 'GraduationCap', rootUrl: 'formations' },
    { label: 'Profil', iconName: 'User', rootUrl: 'profile', badges: signal(this.badges().total) }
  ]);

  activeTab = signal('');

  ngOnInit() {}

  catchTabChange(ev: { tab: string }) {
    this.activeTab.set(ev.tab);
  }
}
