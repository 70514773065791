import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { MissionFormService } from 'src/app/pages/missions/services/mission-form.service';
import { StructureService } from 'src/app/pages/structure/structure.service';
import { Structure } from '@appines/appines_types';

export const CreateMissionHaveStructureGuard: CanActivateFn = (route, state) => {
  const structureService = inject(StructureService);
  const missionFormService = inject(MissionFormService);
  const router: Router = inject(Router);

  function hasOnlyStructureWithMissingFields(structures: Structure[]): boolean {
    return structures.filter((structure) => structure.missingFields && structure.missingFields?.length > 0).length === structures.length;
  }

  if (missionFormService.structures().length > 0 && !hasOnlyStructureWithMissingFields(missionFormService.structures())) {
    return true;
  }

  return structureService.getStructures().pipe(
    map((data) => {
      missionFormService.structures.set(data.data);
      return data.data.length > 0 && !hasOnlyStructureWithMissingFields(data.data) ? true : router.createUrlTree(['/job'], { queryParams: { noStructure: true } });
    })
  );
};
