import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CapacitorSegment } from '@appines/capacitor-segment-analytics';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  constructor() {
    CapacitorSegment.initialize({ webWriteKey: environment.segmentWebKey, androidWriteKey: environment.segmentAndroidKey, iosWriteKey: environment.segmentIOSKey });
    CapacitorSegment.track({ eventName: 'First Event From Capacitor Plugin', properties: { data: 'toto' } });
    CapacitorSegment.screen({ title: 'First Page', category: 'Category Test', properties: { data: 'toto' } });
  }
}
