import {
  Banana,
  Bell,
  BellRing,
  Bookmark,
  Briefcase,
  Building2,
  CalendarDays,
  Camera,
  ChevronLeft,
  ChevronRight,
  CircleAlert,
  CircleHelp,
  CircleUser,
  Clock,
  Compass,
  Copy,
  Cross,
  Ellipsis,
  EllipsisVertical,
  Facebook,
  FileText,
  FolderPlus,
  Globe,
  GraduationCap,
  Handshake,
  Heart,
  House,
  ImagePlus,
  KeyRound,
  LifeBuoy,
  Lock,
  LogOut,
  Mail,
  MapPin,
  MessagesSquare,
  Minus,
  Newspaper,
  Phone,
  Pin,
  Plus,
  QrCode,
  RefreshCw,
  Rocket,
  Send,
  Settings,
  Share,
  ShieldCheck,
  Sparkles,
  SquarePen,
  SquareUser,
  Star,
  Trash2,
  UserPlus,
  Users,
  Waves
} from 'lucide-angular';

export const icons = {
  Banana,
  Bell,
  BellRing,
  Bookmark,
  Briefcase,
  Building2,
  CalendarDays,
  Camera,
  ChevronLeft,
  ChevronRight,
  CircleAlert,
  CircleHelp,
  CircleUser,
  Clock,
  Compass,
  Copy,
  Cross,
  Ellipsis,
  EllipsisVertical,
  Facebook,
  FileText,
  FolderPlus,
  Globe,
  GraduationCap,
  Handshake,
  Heart,
  House,
  ImagePlus,
  KeyRound,
  LifeBuoy,
  Lock,
  LogOut,
  Mail,
  MapPin,
  MessagesSquare,
  Minus,
  Newspaper,
  Phone,
  Pin,
  Plus,
  QrCode,
  RefreshCw,
  Rocket,
  Send,
  Settings,
  Share,
  ShieldCheck,
  Sparkles,
  SquarePen,
  SquareUser,
  Star,
  Trash2,
  UserPlus,
  Users,
  Waves
};
