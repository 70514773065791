<ion-tabs (ionTabsWillChange)="catchTabChange($event)">
  <ion-tab-bar slot="bottom" class="pt-4 desktop:hidden">
    @for (item of tabItems(); track item.label; let i = $index) {
    <ion-tab-button [tab]="item.rootUrl">
      <arc-icon [name]="item.iconName" size="20" strokeWidth="1.5"></arc-icon>

      <ion-label
        class="mt-2"
        [ngClass]="{
            'caption-bold-1': activeTab() === item.rootUrl,
            'caption-regular-1': activeTab() !== item.rootUrl
          }"
      >
        {{ item.label }}
      </ion-label>

      @if (item?.badges && item.badges!() > 0) {
      <div class="absolute left-1/2 top-0 -translate-x-[calc(50%-9px)] transform">
        <arc-badge [count]="item?.badges()" size="medium" variant="tertiary"></arc-badge>
      </div>
      }
    </ion-tab-button>

    @if (i === 1) {
    <div class="center-button px-3">
      <arc-button variant="conversion" iconSlot="icon-only" iconName="Plus"></arc-button>
    </div>
    } }
  </ion-tab-bar>
</ion-tabs>
