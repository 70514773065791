import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../../pages/authentication/authentication.service';
import { inject } from '@angular/core';

export const AuthGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> => {
  const authenticationService: AuthenticationService = inject(AuthenticationService);
  const router: Router = inject(Router);

  // verify that user is authenticated
  const result = await authenticationService.checkAuthentication();

  const currentUrl = route?.url?.[0]?.path;

  // Allow access to page, else redirect to login page
  return result ? true : router.navigateByUrl('/get-started', { state: { redirectUri: currentUrl } });
};
