import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  private rendererFactory = inject(RendererFactory2);
  private platform = inject(Platform);

  private renderer: Renderer2 | undefined;
  private faviconElement: HTMLLinkElement | undefined;

  initFaviconService() {
    if (!this.platform.is('capacitor')) {
      this.renderer = this.rendererFactory.createRenderer(null, null);
      this.faviconElement = this.renderer.selectRootElement('#customFavIcon');
      this.updateFavicon();
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
        this.updateFavicon();
      });
    }
  }

  private updateFavicon(): void {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (this.faviconElement) this.faviconElement.href = isDark ? 'assets/images/appines/favicon-light.png' : 'assets/images/appines/favicon-dark.png';
  }
}
