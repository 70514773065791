import { inject, Injectable } from '@angular/core';
import { StateStoreService } from '../state-store/state-store.service';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService extends StateStoreService<boolean> {
  private platform = inject(Platform);

  constructor() {
    super();
    this.setState(false);

    if (!this.platform.is('desktop')) {
      Keyboard.addListener('keyboardWillShow', () => {
        this.setState(true);
      });

      // Listen for the keyboard close event
      Keyboard.addListener('keyboardWillHide', () => {
        this.setState(false);
      });

      // scroll to activeElement when keyboard did show
      Keyboard.addListener('keyboardDidShow', (info) => {
        document.activeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }
}
