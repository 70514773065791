import { enableProdMode, ErrorHandler, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, Router, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { LucideAngularModule } from 'lucide-angular';
import { archimedIcons, ArcToast } from '@appines/archimed';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import '@angular/common/locales/global/fr';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { refreshTokenInterceptor } from './app/interceptors/refresh-token.interceptor';
import { setTokenInterceptor } from './app/interceptors/set-token.interceptor';
import { provideToastr } from 'ngx-toastr';
import * as SentryAngular from '@sentry/angular';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { missingPermissionInterceptor } from './app/interceptors/missing-permission/missing-permission.interceptor';
import { customTransitionAnimation } from './app/custom-nav-animation';
import { DragulaModule } from 'ng2-dragula';
import { CurrencyPipe } from '@angular/common';
import { icons } from 'icons.config';
import { register as registerSwiperElements } from "swiper/element/bundle";


// Call the element loader before the bootstrapModule/bootstrapApplication call
defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

// Required to use Swiper
registerSwiperElements();
bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideIonicAngular({ useSetInputAPI: true, navAnimation: customTransitionAnimation }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([setTokenInterceptor, refreshTokenInterceptor, missingPermissionInterceptor])),
    importProvidersFrom(LucideAngularModule.pick({ ...archimedIcons, ...icons }), DragulaModule.forRoot()), // import icons & Dragula
    provideAnimationsAsync(),
    provideToastr({ toastComponent: ArcToast, tapToDismiss: false }),
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: SentryAngular.TraceService, deps: [Router] },
    provideAppInitializer(() => {
      inject(SentryAngular.TraceService);
    }),
    CurrencyPipe
  ]
});
