import { Routes } from '@angular/router';
import { AutoLoginGuard } from '../../guards/auto-login/auto-login.guard';
import { MobileGuard } from 'src/app/guards/mobile-guard/mobile.guard';

export const authenticationRoutes: Routes = [
  {
    path: 'get-started',
    loadComponent: () => import('./get-started/get-started.page').then((m) => m.GetStartedPage),
    canActivate: [MobileGuard, AutoLoginGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then((m) => m.LoginPage),
    title: 'Se connecter',
    canActivate: [AutoLoginGuard]
  },
  {
    path: 'forget-password',
    loadComponent: () => import('./forget-password/forget-password.page').then((m) => m.ForgetPasswordPage),
    title: 'Mot de passe oublié',
    canActivate: [AutoLoginGuard]
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./reset-password/reset-password.page').then((m) => m.ResetPasswordPage),
    title: 'Réinitialiser le mot de passe',
    canActivate: [AutoLoginGuard]
  }
];
