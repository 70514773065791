import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { inject } from '@angular/core';
import { AuthenticationService } from '../pages/authentication/authentication.service';

/**
 * This interceptor add the Bearer token in each API AppInes request
 * in order to authenticate the current user
 */
export const setTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const header: { [p: string]: string | string[] } = {};
  const authenticationService = inject(AuthenticationService);

  if (req.url.includes(environment.apiUrl) && authenticationService.hasAccessToken()) header['Authorization'] = `Bearer ${authenticationService.getAccessToken()}`;

  const cloneReq = req.clone({ setHeaders: header });

  return next(cloneReq);
};
